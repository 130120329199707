import React from "react"
import styles from "./banyo.module.scss"
import Menubar from "../components/menubar/menubar"
import { useStaticQuery, graphql } from "gatsby"
import ProjectFoursome from "../components/productFoursome/productFoursome"
import Footer from "../components/footer/footer"
import ProjectTopImage from "../img/banyoTop.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons"

const Projeler = () => {
  const data = useStaticQuery(graphql`
    query {
      project1: file(relativePath: { eq: "kensington2.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project2: file(relativePath: { eq: "rotunda2.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project3: file(relativePath: { eq: "brixton2.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project4: file(relativePath: { eq: "regent2.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project5: file(relativePath: { eq: "clapham2.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project6: file(relativePath: { eq: "camden2.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project7: file(relativePath: { eq: "bath2.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project8: file(relativePath: { eq: "mayfair2.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project9: file(relativePath: { eq: "lilith2.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project10: file(relativePath: { eq: "soho2.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project11: file(relativePath: { eq: "arch2.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project12: file(relativePath: { eq: "dot2.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project13: file(relativePath: { eq: "lubra2.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project14: file(relativePath: { eq: "flemen2.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project15: file(relativePath: { eq: "lace2.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project16: file(relativePath: { eq: "pyramid2.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `)
  return (
    <div className={styles.projelerContainer}>
      <div className={styles.leftE}></div>
      <div className={styles.centerSite}>
        <Menubar />
        <div className={styles.imageTopProjelerContainer}>
          <img
            src={ProjectTopImage}
            alt="projects"
            className={styles.imageTopProjeler}
          />
          <div className={styles.verticalLineWhiteProjeler}>
            <div className={styles.socialMediaIcons}>
              <a
                href="https://www.facebook.com/atiaahsap"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size="1x"
                  className={styles.ffIcon}
                />
              </a>
              <br />
              <a
                href="https://www.instagram.com/atiaahsap/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="1x"
                  className={styles.igIcon}
                />
              </a>
            </div>
          </div>
        </div>
        <ProjectFoursome
          /* Product 1-1 */
          iSrc1={data.project1.childImageSharp.fluid.src}
          info1={`KENSINGTON`}
          iLink1={`/banyo/kensington`}
          /* Product 1-2 */
          iSrc2={data.project2.childImageSharp.fluid.src}
          info2={`ROTUNDA`}
          iLink2={`/banyo/rotunda`}
          /* Product 1-3 */
          iSrc3={data.project3.childImageSharp.fluid.src}
          info3={`BRIXTON`}
          iLink3={`/banyo/brixton`}
          /* Product 1-4 */
          iSrc4={data.project4.childImageSharp.fluid.src}
          info4={`REGENT`}
          iLink4={`/banyo/regent`}
        />
        <ProjectFoursome
          /* Product 2-1 */
          iSrc1={data.project5.childImageSharp.fluid.src}
          info1={`CLAPHAM`}
          iLink1={`/banyo/clapham`}
          /* Product 2-2 */
          iSrc2={data.project6.childImageSharp.fluid.src}
          info2={`CAMDEN`}
          iLink2={`/banyo/camden`}
          /* Product 2-3 */
          iSrc3={data.project7.childImageSharp.fluid.src}
          info3={`BATH`}
          iLink3={`/banyo/bath`}
          /* Product 2-4 */
          iSrc4={data.project8.childImageSharp.fluid.src}
          info4={`MAYFAIR`}
          iLink4={`/banyo/mayfair`}
        />
        <ProjectFoursome
          /* Product 3-1 */
          iSrc1={data.project9.childImageSharp.fluid.src}
          info1={`LILITH`}
          iLink1={`/banyo/lilith`}
          /* Product 3-2 */
          iSrc2={data.project10.childImageSharp.fluid.src}
          info2={`SOHO`}
          iLink2={`/banyo/soho`}
          /* Product 3-3 */
          iSrc3={data.project11.childImageSharp.fluid.src}
          info3={`ARCH`}
          iLink3={`/banyo/arch`}
          /* Product 3-4 */
          iSrc4={data.project12.childImageSharp.fluid.src}
          info4={`DOT`}
          iLink4={`/banyo/dot`}
        />
        <ProjectFoursome
          /* Product 4-1 */
          iSrc1={data.project13.childImageSharp.fluid.src}
          info1={`LUBRA`}
          iLink1={`/banyo/lubra`}
          /* Product 4-2 */
          iSrc2={data.project14.childImageSharp.fluid.src}
          info2={`FLEMEN`}
          iLink2={`/banyo/flemen`}
          /* Product 4-3 */
          iSrc3={data.project15.childImageSharp.fluid.src}
          info3={`LACE`}
          iLink3={`/banyo/lace`}
          /* Product 4-4 */
          iSrc4={data.project16.childImageSharp.fluid.src}
          info4={`PYRAMID`}
          iLink4={`/banyo/pyramid`}
        />
        <Footer />
      </div>
      <div className={styles.rightE}></div>
    </div>
  )
}

export default Projeler
